

























































import Vue from "vue";
import apiClient from "@/services/apiClient";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      valid: false,
      loading: false,
      error: "",
      ruleRequired: (value) => !!value || this.$t("REQUIRED"),
      data: {},
    };
  },
  methods: {
    async submit() {
      this.error = "";
      this.loading = true;
      try {
        const accessToken = await apiClient.authLogin(
          this.data.loginName,
          this.data.password
        );
        if (accessToken) {
          userManager.setAccessToken(accessToken);
          location.reload();
        }
      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
      }
    },
  },
});
